import useAuthenticated from "../hooks/state-access/useAuthenticated"
import { Route, RouteProps, Redirect } from "react-router-dom"

export default function AuthenticatedRoute(props: RouteProps) {
    const isAuthenticated = useAuthenticated()

    return (
        isAuthenticated ?
            <Route {...props} /> :
            <Redirect to="/auth/login" />
    )
}
