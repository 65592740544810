import { WindowView } from "../../../interfaces/WindowView"
import axios from "axios"
import { useQuery } from "react-query"

interface WindowProductVariables {
    windowId: string
}

type WindowProductData = WindowView

export function useWindowProduct(variables: WindowProductVariables) {
    const getWindowProduct = async () => {
        return (await axios.get<WindowProductData>(`/api/windows/${variables.windowId}`)).data
    }

    return useQuery<WindowProductData>(['getWindowProduct', variables.windowId], getWindowProduct)
}
