import { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/Store"
import axios from "axios"
import { invalidateAccessToken } from "../../store/slices/AuthenticationSlice"

interface AuthenticatedAxiosProviderProps {
    children?: ReactNode
}

export default function AuthenticatedAxiosProvider({ children }: AuthenticatedAxiosProviderProps) {
    const accessToken = useSelector((state: RootState) => state.authentication.access_token)
    const dispatch = useDispatch()

    useEffect(() => {
        if (process.env.REACT_APP_API_URL === undefined) {
            throw new Error("Undefined API URL.")
        }

        axios.defaults.baseURL = process.env.REACT_APP_API_URL

        const requestInterceptorId = axios.interceptors.request.use(
            async (config) => {
                if (accessToken !== undefined) {
                    config.headers = {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }

                return config
            },
            (error) => Promise.reject(error)
        )

        const responseInterceptorId = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response !== undefined && error.response.status === 401 && !error.config._retry) {
                    dispatch(invalidateAccessToken())
                }

                return Promise.reject(error)
            }
        )

        return () => {
            axios.interceptors.request.eject(requestInterceptorId)
            axios.interceptors.response.eject(responseInterceptorId)
        }
    }, [accessToken, dispatch])

    return (
        <>
            { children }
        </>
    )
}
