import {
    Button,
    chakra,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HTMLChakraProps,
    Input,
    Stack
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import useAuthenticate from "../../../components/hooks/backend-access/mutations/useAuthenticate"
import { useDispatch } from "react-redux"
import { setAccessToken } from "../../../store/slices/AuthenticationSlice"
import { useHistory } from "react-router-dom"

interface LoginFormData {
    email: string
    password: string
}

export default function LoginForm(props: HTMLChakraProps<'form'>) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { mutateAsync, isLoading } = useAuthenticate()
    const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm<LoginFormData>()

    const onSubmit = async (values: LoginFormData) => {
        try {
            const response = await mutateAsync(values)
            dispatch(setAccessToken(response.accessToken))
            history.push('/dashboard')
        } catch (e: unknown) {
            console.log(e)
        }
    }

    const onError = (error: any) => {
        console.log('error: ', error)
    }

    return (
        <chakra.form
            onSubmit={handleSubmit(onSubmit, onError)}
            {...props}
        >
            <Stack spacing="6">
                <FormControl id="email" isInvalid={errors.email !== undefined}>
                    <FormLabel>Adresa de email</FormLabel>
                    <Input
                        type="email"
                        autoComplete="email"
                        {...register('email', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.email && errors.email.message }
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password !== undefined}>
                    <FormLabel>Parola</FormLabel>
                    <Input
                        type="password"
                        autoComplete="password"
                        {...register('password', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.password && errors.password.message }
                    </FormErrorMessage>
                </FormControl>
                <Button type="submit" colorScheme="blue" size="lg" fontSize="md" isLoading={isSubmitting || isLoading}>
                    Autentifica-te
                </Button>
            </Stack>
        </chakra.form>
    )
}
