import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import storage from "redux-persist/lib/storage"
import authenticationReducer from './slices/AuthenticationSlice'
import labelQueueReducer from './slices/LabelQueueSlice'

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    labelQueue: labelQueueReducer
})

export const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>

export function getStoreAndPersistor() {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    })

    const persistor = persistStore(store)

    return { store, persistor }
}
