import useCurrentUser from "../../../components/hooks/backend-access/queries/useCurrentUser"
import { SidebaredLayout } from "../../../components/user-interface/layout/SidebaredLayout"
import { PlainHeader } from "../../../components/user-interface/header/PlainHeader"

export function DashboardPage() {
    return (
        <SidebaredLayout>
            <PlainHeader title="Vedere de ansamblu" />
        </SidebaredLayout>
    )
}
