import { PlainHeader } from "../../../../../components/user-interface/header/PlainHeader"
import { SidebaredLayout } from "../../../../../components/user-interface/layout/SidebaredLayout"
import { RightCustomSidebarLayout } from "../../../../../components/user-interface/layout/RightCustomSidebarLayout"
import { Button, Stack, Text } from "@chakra-ui/react"
import Card from "../../../../../components/user-interface/cards/Card"
import { CreateWindowForm } from "./CreateWindowForm"

export function CreateWindowPage() {
    return (
        <SidebaredLayout>
            <PlainHeader title="Creează o fereastra" />
            <RightCustomSidebarLayout rightSidebar={<CreateWindowPageSidebar />}>
                <Card>
                    <CreateWindowForm  />
                </Card>
            </RightCustomSidebarLayout>
        </SidebaredLayout>
    )
}

function CreateWindowPageSidebar() {
    return (
        <Stack p="4" bg="red.600" shadow="lg" rounded="lg">
            <Text textAlign="left" as="b" color="white">Acțiuni</Text>
            <Button isFullWidth={true} colorScheme="green" type="submit" form="create-window-form">Creează fereastra</Button>
        </Stack>
    )
}
