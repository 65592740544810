import { Box, Button, Spacer, Stack, Text } from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import { useHistory } from "react-router-dom"

export function LabelQueueFooter() {
    const history = useHistory()
    const labelQueueState = useSelector((state: RootState) => state.labelQueue.state)
    const labelQueueItems = useSelector((state: RootState) => state.labelQueue.products.length)
    const labelQueueLabels = useSelector((state: RootState) => {
        if (state.labelQueue.products.length !== 0) {
            return state.labelQueue.products.map(p => p.quantity).reduce((a, b) => Number(a) + Number(b))
        } else {
            return 0
        }
    })

    return (
        labelQueueState !== 'unopened' ?
            <Box bg="red.700" mt="auto" px="8" py="6" align="center" borderBottom="2px" borderColor="red.900" color="white">
                <Stack direction="row" align="center">
                    <Stack direction="column" spacing="0" textAlign="left">
                        <Text as="b" fontSize="xl">Listă de printare etichete deschisă</Text>
                        <Text fontSize="lg">{labelQueueItems} produse - {labelQueueLabels} etichete</Text>
                    </Stack>
                    <Spacer />
                    <Button colorScheme="red" onClick={() => history.push('/dashboard/printing/label-queue')}>Mergi la printare</Button>
                </Stack>
            </Box> :
            <></>
    )
}
