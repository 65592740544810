import { Switch } from "react-router-dom"
import AuthenticatedRoute from "../../components/routing/AuthenticatedRoute"
import { DashboardPage } from "./dashboard-page/DashboardPage"
import { LabelQueuePage } from "./printing/label-queue-page/LabelQueuePage"
import { ProductsRouter } from "./products/ProductsRouter"
import { IssueReceiptPage } from "./issuing/receipt-page/IssueReceiptPage"

export function DashboardRouter() {
    return (
        <Switch>
            <AuthenticatedRoute exact path="/dashboard" component={DashboardPage} />
            <AuthenticatedRoute path="/dashboard/products" component={ProductsRouter} />
            <AuthenticatedRoute exact path="/dashboard/printing/label-queue" component={LabelQueuePage} />
            <AuthenticatedRoute exact path="/dashboard/issuing/receipt" component={IssueReceiptPage} />
        </Switch>
    )
}
