import { WindowView } from "../../../interfaces/WindowView"
import axios from "axios"
import { useMutation } from "react-query"

interface CreateStockEntryVariables {
    productId: string
    quantity: number
}

interface CreateStockEntryData {
    id: number
    product: WindowView
    quantity: number
    createdAt: Date
}

export function useCreateStockEntry() {
    const createStockEntry = async (variables: CreateStockEntryVariables) => {
        return (await axios.post<CreateStockEntryData>('/api/stocks/entries/create', variables)).data
    }

    return useMutation<CreateStockEntryData, Error, CreateStockEntryVariables>('createStockEntry', createStockEntry)
}
