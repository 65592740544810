import { PlainHeader } from "../../../../components/user-interface/header/PlainHeader"
import { Button, HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { SidebaredLayout } from "../../../../components/user-interface/layout/SidebaredLayout"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store/Store"
import { RightCustomSidebarLayout } from "../../../../components/user-interface/layout/RightCustomSidebarLayout"
import Card from "../../../../components/user-interface/cards/Card"
import { clearQueue, LabelQueueProduct, removeProductFromQueue } from "../../../../store/slices/LabelQueueSlice"
import { useCreateLabelQueueTask } from "../../../../components/hooks/backend-access/mutations/useCreateLabelQueueTask"

export function LabelQueuePage() {
    const dispatch = useDispatch()
    const productsForLabel = useSelector((state: RootState) => state.labelQueue.products)

    const removeFromQueue = (product: LabelQueueProduct) => {
        dispatch(removeProductFromQueue(product))
    }

    return (
        <SidebaredLayout>
            <PlainHeader title="Printează etichete" subtitle={`${productsForLabel.length} itemi în listă`} subtitleAwaitsContent={false}/>
            <RightCustomSidebarLayout rightSidebar={<LabelQueueRightSidebar />}>
                <Card py="4" px="4">
                    <Table colorScheme="blackAlpha">
                        <Thead>
                            <Tr>
                                <Th>Cod produs</Th>
                                <Th>Cantitate</Th>
                                <Th>Acțiuni</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                productsForLabel?.map(product => (
                                    <Tr>
                                        <Td>{ product.productId }</Td>
                                        <Td>{ product.quantity }</Td>
                                        <Td>
                                            <HStack>
                                                <Button colorScheme="red" onClick={() => removeFromQueue(product)}>Elimină din listă</Button>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                </Card>
            </RightCustomSidebarLayout>
        </SidebaredLayout>
    )
}

function LabelQueueRightSidebar() {
    const dispatch = useDispatch()
    const productsForLabel = useSelector((state: RootState) => state.labelQueue.products)
    const { mutateAsync } = useCreateLabelQueueTask()

    const sendToPrinter = async () => {
        if (productsForLabel.length === 0) {
            return
        }

        try {
            await mutateAsync({
                items: productsForLabel
            })

            dispatch(clearQueue())
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Stack p="4" bg="red.600" color="white">
            <Text textAlign="left" as="b">Acțiuni</Text>
            <Button isFullWidth={true} colorScheme="green" onClick={() => sendToPrinter()} isDisabled={productsForLabel.length === 0}>Trimite către imprimantă</Button>
        </Stack>
    )
}
