import axios from "axios"
import { useMutation } from "react-query"
import { WindowView } from "../../../interfaces/WindowView"

interface CreateWindowProductVariables {
    height: number
    width: number
    openings: number
    color: string
    price: number
    state: 'OLD' | 'NEW'
    vatRate: number
    initialStock: number
    initialUnitValue: number
}

type CreateWindowProductData = WindowView

export function useCreateWindowProduct() {
    const createWindowProduct = async (variables: CreateWindowProductVariables) => {
        return (await axios.post<CreateWindowProductData>('/api/windows', variables)).data
    }

    return useMutation<CreateWindowProductData, Error, CreateWindowProductVariables>('createWindowProduct', createWindowProduct)
}
