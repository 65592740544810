import axios from "axios"
import { useMutation } from "react-query"

interface CreateUserVariables {
    email: string
    password: string
    firstName?: string
    lastName?: string
}

interface CreateUserData {
    email: string
    password: string
    firstName?: string
    lastName?: string
}

export function useCreateUser() {
    const createUser = async (variables: CreateUserVariables) => {
        return (await axios.post<CreateUserData>('/api/auth/register', variables)).data
    }

    return useMutation<CreateUserData, Error, CreateUserVariables>('createUser', createUser)
}
