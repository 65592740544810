import { Flex, Spacer, Text } from "@chakra-ui/react"
import { UserBox } from "./UserBox"
import { SidebarNavigationMenu } from "./SidebarNavigationMenu"

export function Sidebar() {
    return (
        <Flex
            direction="column"
            h="100vh"
            bg="red.500"
            p="4"
            textColor="white"
        >
            <Text as="b" fontSize="2xl">APC Inventory</Text>
            <SidebarNavigationMenu />
            <Spacer />
            <UserBox />
        </Flex>
    )
}

