import {
    chakra,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    HTMLChakraProps,
    Input, InputGroup, InputRightAddon, Select,
    Stack
} from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useCreateWindowProduct } from "../../../../../components/hooks/backend-access/mutations/useCreateWindowProduct"

interface CreateWindowFormData {
    height: number
    width: number
    openings: number
    color: string
    price: number
    state: 'OLD' | 'NEW'
    vatRate: number
    initialStock: number
    initialUnitValue: number
}

export function CreateWindowForm(props: HTMLChakraProps<'form'>) {
    const history = useHistory()
    const { mutateAsync } = useCreateWindowProduct()
    const { handleSubmit, register, formState: { errors } } = useForm<CreateWindowFormData>()

    const onSubmit = async (values: CreateWindowFormData) => {
        try {
            await mutateAsync(values)
            history.push('/dashboard/products/windows')
        } catch (e) {
            console.log(e)
        }
    }

    const onError = (error: any) => {
        console.log('error: ', error)
    }

    return (
        <chakra.form
            onSubmit={handleSubmit(onSubmit, onError)}
            id="create-window-form"
            {...props}
        >
            <Stack spacing="6">
                <HStack>
                    <FormControl id="height" isInvalid={errors.height !== undefined}>
                        <FormLabel>Înălțime</FormLabel>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="1200 (mm)"
                                {...register('height', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="mm" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.height && errors.height.message }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl id="width" isInvalid={errors.width !== undefined}>
                        <FormLabel>Lungime</FormLabel>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="1000 (mm)"
                                {...register('width', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="mm" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.width && errors.width.message }
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl id="openings" isInvalid={errors.openings !== undefined}>
                        <FormLabel>Deschideri</FormLabel>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="2 (ct.)"
                                {...register('openings', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="canate" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.openings && errors.openings.message }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl id="color" isInvalid={errors.color !== undefined}>
                        <FormLabel>Culoare</FormLabel>
                        <Input
                            type="text"
                            value="ALB"
                            {...register('color', {
                                required: 'This is required.'
                            })}
                        />
                        <FormErrorMessage>
                            { errors.color && errors.color.message }
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl id="state" isInvalid={errors.state !== undefined}>
                        <FormLabel>Starea produsului</FormLabel>
                        <Select
                            {...register('state', {
                                required: 'This is required.'
                            })}
                        >
                            <option value="NEW">Nou</option>
                            <option value="OLD">Second-hand</option>
                        </Select>
                        <FormErrorMessage>
                            { errors.state && errors.state.message }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl id="price" isInvalid={errors.price !== undefined}>
                        <FormLabel>Preț</FormLabel>
                        <InputGroup>
                            <Input
                                type="text"
                                placeholder="300.00 (RON)"
                                {...register('price', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="RON" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.price && errors.price.message }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl id="vatRate" isInvalid={errors.vatRate !== undefined}>
                        <FormLabel>Cotă T.V.A.</FormLabel>
                        <Select
                            {...register('vatRate', {
                                required: 'This is required.'
                            })}
                        >
                            <option value="19">19%</option>
                            <option value="5">5%</option>
                        </Select>
                        <FormErrorMessage>
                            { errors.vatRate && errors.vatRate.message }
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl id="initialStock" isInvalid={errors.initialStock !== undefined}>
                        <FormLabel>Stoc inițial</FormLabel>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="2 (buc.)"
                                {...register('initialStock', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="bucăți" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.initialStock && errors.initialStock.message }
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl id="initialUnitValue" isInvalid={errors.initialUnitValue !== undefined}>
                        <FormLabel>Valoare unitară a stocului inițial</FormLabel>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="220.00"
                                {...register('initialUnitValue', {
                                    required: 'This is required.'
                                })}
                            />
                            <InputRightAddon children="RON" />
                        </InputGroup>
                        <FormErrorMessage>
                            { errors.initialUnitValue && errors.initialUnitValue.message }
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
            </Stack>
        </chakra.form>
    )
}
