import axios from "axios"
import { useQuery } from "react-query"

interface CurrentUserData {
    email: string
    firstName?: string
    lastName?: string
}

export default function useCurrentUser() {
    const getCurrentUser = async () => {
        return (await axios.get<CurrentUserData>('/api/auth/current')).data
    }

    return useQuery('/api/auth/current', getCurrentUser)
}
