import { WindowView } from "../../../interfaces/WindowView"
import axios from "axios"
import { useQuery } from "react-query"

type AllWindowProductsData = WindowView[]

export function useAllWindowProducts() {
    const getAllWindowProducts = async () => {
        return (await axios.get<AllWindowProductsData>('/api/windows')).data
    }

    return useQuery<AllWindowProductsData>('getAllWindowProducts', getAllWindowProducts)
}
