import { Box, ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import MainRouter from "./routes/MainRouter"
import './assets/stylesheets/fonts.css'
import { getStoreAndPersistor } from "./store/Store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import AuthenticatedAxiosProvider from "./components/authentication/AuthenticatedAxiosProvider"
import { QueryClient, QueryClientProvider } from "react-query"

const theme = extendTheme({
    fonts: {
        heading: `-apple-system, BlinkMacSystemFont, "Inter", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        body: `-apple-system, BlinkMacSystemFont, "Inter", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
    }
})

export const { store, persistor } = getStoreAndPersistor()

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate persistor={persistor} loading={<h1>Loading persisted state...</h1>}>
              <AuthenticatedAxiosProvider>
                  <QueryClientProvider client={queryClient}>
                      <ChakraProvider theme={theme}>
                          <ColorModeScript />
                          <MainRouter />
                      </ChakraProvider>
                  </QueryClientProvider>
              </AuthenticatedAxiosProvider>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your routes to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your routes, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
