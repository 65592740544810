import {
    Button,
    chakra,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HTMLChakraProps,
    Input,
    Stack
} from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import useAuthenticate from "../../../components/hooks/backend-access/mutations/useAuthenticate"
import { useForm } from "react-hook-form"
import { setAccessToken } from "../../../store/slices/AuthenticationSlice"
import { useCreateUser } from "../../../components/hooks/backend-access/mutations/useCreateUser"

interface RegisterFormData {
    email: string
    password: string
    firstName: string
    lastName: string
}

export function RegisterForm(props: HTMLChakraProps<'form'>) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { mutateAsync, isLoading } = useCreateUser()
    const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm<RegisterFormData>()

    const onSubmit = async (values: RegisterFormData) => {
        try {
            const response = await mutateAsync(values)
            history.push('/auth/login')
        } catch (e: unknown) {
            console.log(e)
        }
    }

    const onError = (error: any) => {
        console.log('error: ', error)
    }

    return (
        <chakra.form
            onSubmit={handleSubmit(onSubmit, onError)}
            {...props}
        >
            <Stack spacing="6">
                <FormControl id="firstName" isInvalid={errors.firstName !== undefined}>
                    <FormLabel>Prenume</FormLabel>
                    <Input
                        type="firstName"
                        autoComplete="firstName"
                        {...register('firstName', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.firstName && errors.firstName.message }
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="lastName" isInvalid={errors.lastName !== undefined}>
                    <FormLabel>Nume</FormLabel>
                    <Input
                        type="lastName"
                        autoComplete="lastName"
                        {...register('lastName', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.lastName && errors.lastName.message }
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="email" isInvalid={errors.email !== undefined}>
                    <FormLabel>Adresă de email</FormLabel>
                    <Input
                        type="email"
                        autoComplete="email"
                        {...register('email', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.email && errors.email.message }
                    </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password !== undefined}>
                    <FormLabel>Parolă</FormLabel>
                    <Input
                        type="password"
                        autoComplete="password"
                        {...register('password', {
                            required: "This is required."
                        })}
                    />
                    <FormErrorMessage>
                        { errors.password && errors.password.message }
                    </FormErrorMessage>
                </FormControl>
                <Button type="submit" colorScheme="blue" size="lg" fontSize="md" isLoading={isSubmitting || isLoading}>
                    Creează-ți contul
                </Button>
            </Stack>
        </chakra.form>
    )
}
