import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface LabelQueueProduct {
    productId: string
    productName?: string
    quantity: number
}

interface LabelQueueState {
    state: 'unopened' | 'opened' | 'printing' | 'printed'
    products: LabelQueueProduct[]
}

const initialState: LabelQueueState = { state: 'unopened', products: [] }

const labelQueueSlice = createSlice({
    name: 'labelQueue',
    initialState,
    reducers: {
        openLabelQueue(state) {
            state.state = 'opened'
        },
        addProductToQueue(state, action: PayloadAction<LabelQueueProduct>) {
            state.state = 'opened'

            if (state.products.filter(p => p.productId === action.payload.productId).length !== 0) {
                const product = state.products.find(p => p.productId === action.payload.productId)
                if (product !== undefined) {
                    product.quantity += action.payload.quantity
                }
            } else {
                state.products.push(action.payload)
            }
        },
        removeProductFromQueue(state, action: PayloadAction<LabelQueueProduct>) {
            state.products = state.products.filter(p => p.productId !== action.payload.productId)

            if (state.products.length === 0) {
                state.state = 'unopened'
            }
        },
        clearQueue(state) {
            state.products = []
            state.state = 'unopened'
        },
        setQueueAsPrinting(state) {
            state.state = 'printing'
        },
        setQueueAsPrinted(state) {
            state.state = 'printed'
        }
    }
})

export const { openLabelQueue, addProductToQueue, removeProductFromQueue, clearQueue, setQueueAsPrinted, setQueueAsPrinting } = labelQueueSlice.actions
export default labelQueueSlice.reducer
