import { Switch } from "react-router-dom"
import LoginPage from "./login-page/LoginPage"
import StrictUnauthenticatedRoute from "../../components/routing/StrictUnauthenticatedRoute"
import { RegisterPage } from "./register-page/RegisterPage"

export default function AuthenticationRouter() {
    return (
        <Switch>
            <StrictUnauthenticatedRoute exact path="/auth/login" component={LoginPage} />
            <StrictUnauthenticatedRoute exact path="/auth/register" component={RegisterPage} />
        </Switch>
    )
}
