import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AuthenticationState {
    access_token?: string
}

const initialState: AuthenticationState = { access_token: undefined }

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAccessToken(state, action: PayloadAction<string>) {
            state.access_token = action.payload
        },
        invalidateAccessToken(state) {
            state.access_token = undefined
        }
    }
})

export const { setAccessToken, invalidateAccessToken } = authenticationSlice.actions
export default authenticationSlice.reducer
