import { Box, Button, Heading, Link, useColorModeValue } from "@chakra-ui/react"
import Card from "../../../components/user-interface/cards/Card"
import LoginForm from "./LoginForm"
import { useHistory } from "react-router-dom"

export default function LoginPage() {
    const history = useHistory()

    return (
        <Box
            bg={useColorModeValue('gray.50', 'inherit')}
            minH="100vh"
            py="12"
            px={{ base: '4', lg: '8' }}
        >
            <Box maxW="md" mx="auto">
                <Heading mb="6" textAlign="center" size="xl" fontWeight="extrabold">
                    Intră în contul tău
                </Heading>
                <Card>
                    <LoginForm />
                    <Button mt="4" colorScheme="orange" isFullWidth={true} onClick={() => history.push('/auth/register')}>Creează un cont nou</Button>
                </Card>
            </Box>
        </Box>
    )
}

