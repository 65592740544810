import { SidebaredLayout } from "../../../../../components/user-interface/layout/SidebaredLayout"
import { PlainHeader } from "../../../../../components/user-interface/header/PlainHeader"
import { FullCardedLayout } from "../../../../../components/user-interface/layout/FullCardedLayout"
import { Button, HStack, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { useAllWindowProducts } from "../../../../../components/hooks/backend-access/queries/useAllWindowProducts"
import { useDispatch } from "react-redux"
import { addProductToQueue } from "../../../../../store/slices/LabelQueueSlice"
import { useHistory } from "react-router-dom"

export function ViewAllWindowsPage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { data: windows, isLoading } = useAllWindowProducts()

    const addToPrintQueue = (id: string) => {
        dispatch(addProductToQueue({
            productId: id,
            quantity: 1
        }))
    }

    const hrefToDetailProductPage = (id: string) => {
        history.push(`/dashboard/products/windows/detail/${id}`)
    }

    return (
        <SidebaredLayout>
            <PlainHeader title="Vezi toate ferestrele" subtitle={`${windows?.length} ferestre găsite`} subtitleAwaitsContent={isLoading}/>
            <FullCardedLayout>
                <Table colorScheme="blackAlpha">
                    <Thead>
                        <Tr>
                            <Th>Înălțime</Th>
                            <Th>Lungime</Th>
                            <Th>Preț</Th>
                            <Th>Stoc</Th>
                            <Th>Stare</Th>
                            <Th>Acțiuni</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            windows?.map(window => (
                                <Tr>
                                    <Td>{ window.height }mm</Td>
                                    <Td>{ window.width }mm</Td>
                                    <Td>{ window.price } RON</Td>
                                    <Td>X BUC</Td>
                                    <Td>{ window.state }</Td>
                                    <Td>
                                        <HStack>
                                            <Button colorScheme="red" onClick={() => hrefToDetailProductPage(window.id)}>Vezi detalii</Button>
                                            <Button colorScheme="orange" onClick={() => addToPrintQueue(window.id)}>Adaugă la etichetat</Button>
                                        </HStack>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </FullCardedLayout>
        </SidebaredLayout>
    )
}
