import { Box, Heading, useColorModeValue } from "@chakra-ui/react"
import Card from "../../../components/user-interface/cards/Card"
import { RegisterForm } from "./RegisterForm"

export function RegisterPage() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'inherit')}
            minH="100vh"
            py="12"
            px={{ base: '4', lg: '8' }}
        >
            <Box maxW="md" mx="auto">
                <Heading mb="6" textAlign="center" size="xl" fontWeight="extrabold">
                    Creează un cont nou
                </Heading>
                <Card>
                    <RegisterForm />
                </Card>
            </Box>
        </Box>
    )
}
