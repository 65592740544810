import { useHistory } from "react-router-dom"
import { AiFillPrinter, AiOutlineDashboard, AiOutlinePlus, BiWindow, FaCashRegister, GiSellCard } from "react-icons/all"

interface NavigationItem {
    name: string
    separator: boolean
    icon?: any
    href?: string
    current?: boolean
    children?: {
        name: string
        href: string
    }[]
}

export function useSidebarNavigationMenu(): NavigationItem[] {
    const history = useHistory()

    const isPath = (path: string): boolean => {
        return history.location.pathname === path
    }

    return [
        {
            name: 'DASHBOARD',
            separator: true
        },
        {
            name: 'Vedere de ansamblu',
            separator: false,
            icon: AiOutlineDashboard,
            href: '/dashboard',
            current: isPath('/dashboard')
        },
        {
            name: 'VÂNZARE',
            separator: true
        },
        {
            name: 'Emite bon fiscal',
            separator: false,
            icon: FaCashRegister,
            href: '/dashboard/issuing/receipt',
            current: isPath('/dashboard/issuing/receipt')
        },
        {
            name: 'STOCURI',
            separator: true
        },
        {
            name: 'Vezi ferestre',
            separator: false,
            icon: BiWindow,
            current: isPath('/dashboard/products/windows'),
            href: '/dashboard/products/windows'
        },
        {
            name: 'Creeaza ferestre',
            separator: false,
            icon: AiOutlinePlus,
            current: isPath('/dashboard/products/windows/create'),
            href: '/dashboard/products/windows/create'
        },
        {
            name: 'PRINTARE',
            separator: true
        },
        {
            name: 'Printeaza etichete',
            separator: false,
            icon: AiFillPrinter,
            current: isPath('/dashboard/printing/label-queue'),
            href: '/dashboard/printing/label-queue'
        }
    ]
}
