import { Box, Flex, FlexProps } from "@chakra-ui/react"

interface CardPropertyProps extends FlexProps {
    label: string
    value: string
}

export function CardProperty({ label, value, ...props }: CardPropertyProps) {
    return (
        <Flex
            as="dl"
            direction={{ base: 'column', sm: 'row' }}
            px="6"
            py="4"
            _even={{ bg: "gray.50" }}
            {...props}
        >
            <Box as="dt" minW="180px">
                { label }
            </Box>
            <Box as="dd" flex="1" fontWeight="semibold">
                { value }
            </Box>
        </Flex>
    )
}
