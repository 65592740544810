import { ReactNode } from "react"
import {Box, Flex, HStack, Spacer, Stack, Text, VStack} from "@chakra-ui/react"
import { Sidebar } from "../sidebar/Sidebar"
import {LabelQueueFooter} from "../footer/LabelQueueFooter";

interface SidebaredLayoutProps {
    children?: ReactNode
}

export function SidebaredLayout({ children }: SidebaredLayoutProps) {
    return (
        <Flex height="100vh">
            <Box w="20vw" borderRight="2px" borderColor="red.600">
                <Sidebar />
            </Box>
            <Flex flex="1" direction="column" bgColor="gray.100">
                { children }
                <LabelQueueFooter />
            </Flex>
        </Flex>
    )
}
