import { useDispatch } from "react-redux"
import useCurrentUser from "../../hooks/backend-access/queries/useCurrentUser"
import { useMemo } from "react"
import gravatar from "gravatar"
import { invalidateAccessToken } from "../../../store/slices/AuthenticationSlice"
import {
    Avatar,
    Button,
    HStack,
    Popover,
    PopoverArrow,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Skeleton,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react"

export function UserBox() {
    const dispatch = useDispatch()
    const {data: currentUser, isLoading} = useCurrentUser()
    const gravatarUrl = useMemo(() => {
        if (currentUser === undefined) {
            return undefined
        }

        return gravatar.url(currentUser.email, {s: '100', r: 'x', d: 'retro'})
    }, [currentUser])

    const invalidateToken = () => {
        dispatch(invalidateAccessToken())
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Skeleton
                    startColor="red.700"
                    endColor="red.900"
                    isLoaded={!isLoading}
                    rounded="lg"
                >
                    <HStack
                        bg="red.800"
                        rounded="lg"
                        px="3"
                        py="3"
                        align="center"
                    >
                        <Avatar name={currentUser?.firstName + ' ' + currentUser?.lastName} src={gravatarUrl}/>
                        <Stack spacing="-0.5">
                            <Text as="b" fontSize="md">{currentUser?.firstName} {currentUser?.lastName}</Text>
                            <Text fontSize="sm" isTruncated>AUGUPRODCOM S.R.L.</Text>
                        </Stack>
                    </HStack>
                </Skeleton>
            </PopoverTrigger>
            <PopoverContent textColor={useColorModeValue('black', 'white')}>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverHeader>Optiuni cont</PopoverHeader>
                <PopoverContent>
                    <Button onClick={invalidateToken}>
                        Iesi din cont
                    </Button>
                </PopoverContent>
            </PopoverContent>
        </Popover>

    )
}
