import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import AuthenticationRouter from "./authentication/AuthenticationRouter"
import { DashboardRouter } from "./dashboard/DashboardRouter"
import AuthenticatedRoute from "../components/routing/AuthenticatedRoute"

export default function MainRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <AuthenticatedRoute exact path="/">
                    <Redirect to="/dashboard" />
                </AuthenticatedRoute>

                <Route path="/auth" component={AuthenticationRouter} />
                <Route path="/dashboard" component={DashboardRouter} />
            </Switch>
        </BrowserRouter>
    )
}
