import { ReactNode } from "react"
import { Box } from "@chakra-ui/react"

interface FullCardedLayoutProps {
    children?: ReactNode
}

export function FullCardedLayout({ children }: FullCardedLayoutProps) {
    return (
        <Box
            mx={6}
            my={4}
            p={4}
            rounded="lg"
            shadow="lg"
            bg="white"
        >
            { children }
        </Box>
    )
}
