import { ReactNode } from "react"
import { Flex, Heading } from "@chakra-ui/react"

interface CardHeaderProps {
    title: string
    action?: ReactNode
}

export function CardHeader({ title, action }: CardHeaderProps) {
    return (
        <Flex align="center" justify="space-between" px="6" py="4" borderBottomWidth="1px">
            <Heading as="h2" fontSize="lg">
                { title }
            </Heading>
            { action }
        </Flex>
    )
}
