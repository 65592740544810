import { ReactNode } from "react"
import { Box, Flex } from "@chakra-ui/react"

interface RightCustomSidebarLayoutProps {
    rightSidebar?: ReactNode
    children?: ReactNode
}

export function RightCustomSidebarLayout({ rightSidebar, children }: RightCustomSidebarLayoutProps) {
    return (
        <Flex>
            <Box flex="1" px="6" py="4">
                { children }
            </Box>
            <Box w="20vw" mt="-0.5">
                { rightSidebar }
            </Box>
        </Flex>
    )
}
