import { useSidebarNavigationMenu } from "../../hooks/navigation-menu/useSidebarNavigationMenu"
import { Box, Button, Divider, Stack, Text } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

export function SidebarNavigationMenu() {
    const navigationItems = useSidebarNavigationMenu()
    const history = useHistory()

    return (
        <Stack mt="4">
            {
                navigationItems.map((item) => {
                    if (item.separator) {
                        return (
                            <Box pt="3">
                                <Text fontSize="sm" as="b">{item.name}</Text>
                                <Divider mt="1" />
                            </Box>
                        )
                    } else {
                        return (
                            <Button
                                colorScheme="red"
                                textAlign="left"
                                justifyContent="start"
                                leftIcon={<item.icon />}
                                isFullWidth={true}
                                isActive={item.current} onClick={() => history.push(item.href ?? "")}
                            >
                                { item.name }
                            </Button>
                        )
                    }
                })
            }
        </Stack>
    )
}
