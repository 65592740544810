import { Switch } from "react-router-dom"
import AuthenticatedRoute from "../../../components/routing/AuthenticatedRoute"
import { ViewAllWindowsPage } from "./windows/view-all-windows-page/ViewAllWindowsPage"
import { ViewWindowDetailsPage } from "./windows/view-window-details-page/ViewWindowDetailsPage"
import { CreateWindowPage } from "./windows/create-window-page/CreateWindowPage"

export function ProductsRouter() {
    return (
        <Switch>
            <AuthenticatedRoute exact path="/dashboard/products/windows" component={ViewAllWindowsPage} />
            <AuthenticatedRoute exact path="/dashboard/products/windows/detail/:id" component={ViewWindowDetailsPage} />
            <AuthenticatedRoute exact path="/dashboard/products/windows/create" component={CreateWindowPage} />
        </Switch>
    )
}
