import useAuthenticated from "../hooks/state-access/useAuthenticated"
import { Redirect, Route, RouteProps } from "react-router-dom"

export default function StrictUnauthenticatedRoute(props: RouteProps) {
    const isAuthenticated = useAuthenticated()

    return (
        isAuthenticated ?
            <Redirect to="/auth/login" /> :
            <Route {...props} />
    )
}
