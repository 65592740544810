import {
    Button,
    chakra,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Stack,
    Switch,
    Text,
    useDisclosure
} from "@chakra-ui/react"
import { set, useForm } from "react-hook-form"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { addProductToQueue } from "../../../../../store/slices/LabelQueueSlice"
import { useCreateStockEntry } from "../../../../../components/hooks/backend-access/mutations/useCreateStockEntry"
import { useQueryClient } from "react-query"

interface AddStockEntryModalProps {
    windowId: string
    initialStock: number
    isOpen: boolean,
    onOpen?: () => void
    onClose: () => void
}

export function AddStockEntryModal(props: AddStockEntryModalProps) {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Modifica stocul acestei ferestre</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <AddStockEntryModalForm windowId={props.windowId} initialStock={props.initialStock} onClose={props.onClose}/>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

interface AddStockEntryModalFormProps {
    windowId: string
    initialStock: number
    onClose?: () => void
}

interface AddStockEntryFormData {
    windowId: string
    quantity: string
    printLabel: boolean
    date: Date
}

function AddStockEntryModalForm(props: AddStockEntryModalFormProps) {
    const dispatch = useDispatch()
    const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm<AddStockEntryFormData>()
    const { mutateAsync } = useCreateStockEntry()
    const queryClient = useQueryClient()

    const formQuantity = watch('quantity')
    const shouldAddToQueue = watch('printLabel')

    useEffect(() => {
        setValue("windowId", props.windowId)
    }, [setValue, props.windowId])

    useEffect(() => {
        setValue("date", new Date())
    }, [setValue])

    const onSubmit = async (values: AddStockEntryFormData) => {
        try {
            await mutateAsync({
                productId: values.windowId,
                quantity: Number(values.quantity)
            })

            queryClient.invalidateQueries('getAllWindowProducts').then()
            queryClient.invalidateQueries(['getWindowProduct', values.windowId]).then()

            if (shouldAddToQueue) {
                dispatch(addProductToQueue({
                    productId: values.windowId,
                    quantity: Number(values.quantity)
                }))
            }

            props.onClose?.()
        } catch (e) {
            console.log(e)
        }
    }

    const onError = (error: unknown) => {
        console.log(error)
    }

    return (
        <chakra.form onSubmit={handleSubmit(onSubmit, onError)} mb="4">
            <Stack spacing="4">
                <FormControl id="quantity" isInvalid={errors.quantity !== undefined}>
                    <FormLabel>Cantitatea cu care se modifică stocul</FormLabel>
                    <NumberInput onChange={(num) => {setValue('quantity', num)}}>
                        <NumberInputField
                            {...register('quantity', {
                                required: true
                            })}
                        />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <Text>Noua cantitate: {Number(formQuantity) + props.initialStock}</Text>
                <FormControl id="printLabel" display="flex" alignItems="center">
                    <FormLabel>Adaugă noile elemente in lista de printare?</FormLabel>
                    <Switch {...register('printLabel')} />
                </FormControl>
                <Button colorScheme="green" isFullWidth={true} type="submit" isLoading={isSubmitting}>Modifică</Button>
            </Stack>
        </chakra.form>
    )
}
