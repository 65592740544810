import { Flex, SkeletonText, Spacer, Stack, Text, FlexProps } from "@chakra-ui/react"
import { ReactNode } from "react"

interface PlainHeaderProps extends FlexProps {
    title: string
    subtitle?: string
    subtitleAwaitsContent?: boolean
    children?: ReactNode
}

export function PlainHeader({ title, subtitle, subtitleAwaitsContent, children, ...props }: PlainHeaderProps) {
    return (
        <Flex bg="white" px="8" py="6" align="center" borderBottom="2px" borderColor="gray.200" color="black" {...props}>
            <Stack spacing="0">
                <Text as="b" fontSize="3xl">{ title }</Text>
                <SkeletonSubtitle subtitle={subtitle} subtitleAwaitsContent={subtitleAwaitsContent} />
            </Stack>
            <Spacer />
            { children }
        </Flex>
    )
}

interface SkeletonSubtitleProps {
    subtitle?: string
    subtitleAwaitsContent?: boolean
}

function SkeletonSubtitle({ subtitle, subtitleAwaitsContent }: SkeletonSubtitleProps) {
    return subtitleAwaitsContent !== undefined ?
        <SkeletonText
            startColor="gray.900"
            endColor="white"
            noOfLines={1}
            skeletonHeight="15px"
            mt={2}
            isLoaded={!subtitleAwaitsContent}
        >
            <Text fontSize="xl">{ subtitle }</Text>
        </SkeletonText> :
        <></>
}
