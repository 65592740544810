import { PlainHeader } from "../../../../components/user-interface/header/PlainHeader"
import { RightCustomSidebarLayout } from "../../../../components/user-interface/layout/RightCustomSidebarLayout"
import Card from "../../../../components/user-interface/cards/Card"
import { SidebaredLayout } from "../../../../components/user-interface/layout/SidebaredLayout"
import { Button, Stack, Text } from "@chakra-ui/react"

export function IssueReceiptPage() {
    return (
        <SidebaredLayout>
            <PlainHeader title="Emite un bon fiscal" />
            <RightCustomSidebarLayout rightSidebar={<IssueReceiptSidebar />}>
                <Card>
                    <h1>Hello</h1>
                </Card>
            </RightCustomSidebarLayout>
        </SidebaredLayout>
    )
}

function IssueReceiptSidebar() {
    return (
        <Stack p="4" bg="red.600" shadow="lg" rounded="lg" color="white">
            <Text textAlign="left" as="b">Acțiuni</Text>
        </Stack>
    )
}
