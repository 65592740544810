import axios from "axios"
import { useMutation } from "react-query"

interface CreateLabelQueueTaskVariables {
    items: {
       productId: string
       quantity: number
    }[]
}

export function useCreateLabelQueueTask() {
    const createLabelQueueTask = async (variables: CreateLabelQueueTaskVariables) => {
        return (await axios.post<CreateLabelQueueTaskVariables>('/api/labelqueue', variables)).data
    }

    return useMutation<any, Error, CreateLabelQueueTaskVariables>('createLabelQueueTask', createLabelQueueTask)
}
