import { useParams } from "react-router-dom";
import { SidebaredLayout } from "../../../../../components/user-interface/layout/SidebaredLayout"
import { PlainHeader } from "../../../../../components/user-interface/header/PlainHeader"
import { useWindowProduct } from "../../../../../components/hooks/backend-access/queries/useWindowProduct"
import { RightCustomSidebarLayout } from "../../../../../components/user-interface/layout/RightCustomSidebarLayout"
import { Button, Stack, Text, useDisclosure } from "@chakra-ui/react"
import Card from "../../../../../components/user-interface/cards/Card"
import { AddStockEntryModal } from "./AddStockEntryModal"
import { CardContent } from "../../../../../components/user-interface/cards/CardContent"
import { CardHeader } from "../../../../../components/user-interface/cards/CardHeader"
import { CardProperty } from "../../../../../components/user-interface/cards/CardProperty"

interface ViewWindowDetailsPageParams {
    id: string
}

export function ViewWindowDetailsPage() {
    const params = useParams<ViewWindowDetailsPageParams>();
    const { data: windowData } = useWindowProduct({ windowId: params.id })

    return (
        <SidebaredLayout>
            <PlainHeader title={windowData?.name ?? "asd"} subtitle="FEREASTRĂ" subtitleAwaitsContent={false} />
            <RightCustomSidebarLayout rightSidebar={<ViewWindowDetailsRightSidebar windowId={windowData?.id ?? "asd"} initialStock={0}/>}>
                <Card>
                    <CardHeader title="Informații produs" />
                    <CardContent>
                        <CardProperty label="Pret" value={windowData?.price + ' RON'} />
                        <CardProperty label="Deschideri" value={windowData?.openings + ' ct.'} />
                        <CardProperty label="Culoare" value={windowData?.color ?? ''} />
                        <CardProperty label="Stoc" value="x buc." />
                    </CardContent>
                </Card>
            </RightCustomSidebarLayout>
        </SidebaredLayout>
    )
}

function ViewWindowDetailsRightSidebar(props: {windowId: string, initialStock: number}) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Stack p="4" bg="red.600">
            <Text textAlign="left" as="b" color="white">Acțiuni</Text>
            <Button isFullWidth={true} colorScheme="teal" onClick={onOpen}>Creează intrare în stoc cu acest produs</Button>
            <Button isFullWidth={true} colorScheme="orange" type="submit" isDisabled>Sterge fereastra</Button>
            <AddStockEntryModal isOpen={isOpen} onClose={onClose} windowId={props.windowId} initialStock={props.initialStock} />
        </Stack>
    )
}
