import { useMutation } from "react-query"
import axios from "axios"

interface AuthenticateVariables {
    email: string
    password: string
}

interface AuthenticateData {
    accessToken: string
}

export default function useAuthenticate() {
    const authenticate = async (variables: AuthenticateVariables) => {
        return (await axios.post<AuthenticateData>('/api/auth/login', variables)).data
    }

    return useMutation<AuthenticateData, Error, AuthenticateVariables>('authenticate', authenticate)
}
